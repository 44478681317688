import React from "react";
import "../global.css";
import "../reset.css";
import "./hero.css"
import "../fonts/type.css"

class Hero extends React.Component {
  render() {
    return (
      <main>
        <header className="bg-dark py-2">
            <div className="container px-4 px-lg-5 my-3">
                <div className="text-center text-white">
                    <p className="lead fw-normal text-white-50">
                        In March of 2017, a Pepe-inspired project named “Peperium” was announced. Peperium was a decentralized meme marketplace and trading card game that allowed users to create memes by utilizing Ethereum and IPFS. The Peperium project also had its own token, RARE, which was to be used for meme creation and listing fees. Sadly, the project faded during Series 2, with only 31 different collectibles left behind.
                        <br /><br />
                        Peperium can be found being talked about on old Bitcointalk, Reddit, and Ethereum forums from early 2017. In January of 2023, it was brought back to life by blockchain archaeologists who assisted in rediscovering the project, the IPFS artwork, and original artists and founders. Pieces that have been rediscovered can be wrapped to meet current NFT standards and can now be easily traded.
                        <br /><br />
                        Peperium consists of the earliest 1/1 artworks and art editions on Ethereum. Peperium was revolutionary to the crypto art space as it innovated a new way to connect decentralized artwork to a token. Today, these art pieces are known to be some of the earliest known historical NFT collectibles!
                        {/* In March of 2017, a project named “Peperium” was announced. Peperium was a decentralized Meme marketplace and a trading card game that allowed users to create memes by leveraging Ethereum and IPFS. The Peperium project also had its own token, RARE, which was used for meme creation and listing fees. Sadly, the project fizzled away after Series 2, with only 31 different collectibles left behind. 
                        <br /><br />
                        Blockchain archeologist Obi sought after these lost historical relics preserved on Ethereum's blockchain. Low and behold, ICQPEPE, stumbles across some lost keys containing many precious historical NFT collectibles. Today we bring you, "The Lost Peperium Pepes"
                        <br /><br />
                        Also, it turned out the one and only Scrilla made a Fake Peperium called "TAOWARARE" back in 2017. It's also included in the wrapper. */}
                    </p>

                    <img src="/icons/rare-logo.png" width="120" />

                    <div className="lead fw-normal pt-3">
                      <ul className="list-unstyled">
                        <li className="pb-3">🖼️ Probably the FIRST art collection on Ethereum</li>
                        <li>⛓️ (One of) the first project(s) to utilize IPFS</li>
                      </ul>
                        
                    </div>
                </div>
            </div>
        </header>
      </main>
    );
  }
}

export default Hero
