var axios = require("axios");
const graphEndpoint =
  "https://api.studio.thegraph.com/query/507/peperium/version/latest"

export const getCards = async (address) => {
  var response = await axios.post(graphEndpoint, {
    query: `
    {
        cardBalances(where : { user : "${address}" } ) {
            id
            type {
                id
                isLocked
            }
            unwrapped
            wrappedOfficial
            wrappedUnofficial
      }
    }
    `,
  });
  // console.log("Graph API response:", response.data);
  if (response.data.errors) {
    throw new Error(response.data.errors.message);
  }
  return response.data.data.cardBalances;
};


export const getAllCards = async () => {
  var response = await axios.post(graphEndpoint, {
    query: `
      {
        cardTypes(first: 32, where:{ name_not: "HWNDUPEPE" }) {
          id
          address
          name
          supply
          isLocked
          balances {
            id
            unwrapped
            wrappedOfficial
            wrappedUnofficial
          }
        }
      }
    `,
  });
  // console.log("Graph API response:", response.data);
  if (response.data.errors) {
    throw new Error(response.data.errors.message);
  }
  return response.data.data.cardTypes;
};

const cardAddressToIDArray = [
  { id: "1", address: "0x648445e48093d999966375b30186d433fef9c364" },
  { id: "2", address: "0x51b41e61b654ea852679e374171f08f35119e255" },
  { id: "3", address: "0xa3832b169ac40c23d85eeb1d0c2e51fcac5df5de" },
  { id: "4", address: "0x8b990d8bbb502eaa09ae4badeb8ec85acbe7fb66" },
  { id: "5", address: "0x57e62ee19ffd8f73cd978282a45a1e5ab7341c96" },
  { id: "6", address: "0xa87c5dcc2e24face553cfc0c04d8e22c94c117a2" },
  { id: "7", address: "0xed01ca51723c0f9ebc167e8e382a7cfa73735077" },
  { id: "8", address: "0x99523add649d16e69e2ee913bac893e69e926444" },
  { id: "9", address: "0x9f0bf0e983b227b2c026d4655b9fdf6cd435a66e" },
  { id: "10", address: "0x8f94bc777cb8b6876cd2410da3a6f796733212f5" },
  { id: "11", address: "0x97e5cc2f56e08c6338ac7907e1073a8049d4bc60" },
  { id: "12", address: "0x20cd103d4ed989338e122eb069d9cc51f497151e" },
  { id: "13", address: "0xd3fab082012b7482244379b77d9548495a029f54" },
  { id: "14", address: "0x38a8e6f911ab6163b663f7fa414af03c7ab4b35b" },
  { id: "15", address: "0x08aab3c173639a37c7e5254c2f1c153c3e3444b2" },
  { id: "16", address: "0x76330651533b02b89c563352062265663e410923" },
  { id: "17", address: "0x3ac136f428889e0e8d88b79940dea547eb7ca00d" },
  { id: "18", address: "0x2523f621ce57e0503c114fbd0181b4c385165513" },
  { id: "19", address: "0x5921f43985a027ba74ee110b77dce09b96de943e" },
  { id: "20", address: "0x60e762fa4fcb2ba472b055d64febbfcca000ed6c" },
  { id: "21", address: "0x6160a19ec62392adf43cc22c89c0193432216417" },
  { id: "22", address: "0x4b5cf00ae4d5b1a8dc4e0d81f28e373aadbe59d3" },
  { id: "23", address: "0xba7101b0aaf0f1cf655240ed519c4ecac3394022" },
  { id: "24", address: "0xdaa9cecfe002536574d7958c50950f8f545a9bd4" },
  { id: "25", address: "0xf10e9228221777920d413e74aa40a54b33886ac4" },
  { id: "26", address: "0x11266bf3498fabe08707c16f3ba7fbf526cf9f98" },
  { id: "27", address: "0xa9ddec7e4b31d63aa9feaf77190b242070483b24" },
  { id: "28", address: "0x5443f7d80875afd18737f2a5322161582373ce5e" },
  { id: "29", address: "0x0273b0846a9877af88b3f080fb963d8f51679996" },
  { id: "30", address: "0xc8a1464d5936c9dcb033daa7fc198215e7538292" },
  { id: "31", address: "0x7469580d483e9832b9c68676b5ea17141be97df2" },
  { id: "99", address: "0x95907077585b09068a0d8d1185aab872ec1836c0" },
]

export function getCardIDFromAddress(address) {
  return cardAddressToIDArray.find((card) => card.address.toLowerCase() === address.toLowerCase()).id;
}

export function getAddressFromID(id) {
  return cardAddressToIDArray.find((card) => card.id === id).address;
}
