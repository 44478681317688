import React from "react";
import { Helmet } from "react-helmet"
import { ethers } from "ethers"
import { wrapperAddr } from '../constants'

import "../global.css";
import "../reset.css";
import "./detail.css"
import "../fonts/type.css"

import close from "../../images/i-close.svg"
import { cardImages } from "../constants.jsx";
import { InfuraProviderId } from "../constants.jsx";
import { getAllCards } from "../../services/graph";

const _ = require('lodash')


class Detail extends React.Component {
  constructor(props) {
    super(props);

    const provider = new ethers.providers.InfuraProvider("homestead", {
      projectId: InfuraProviderId,
    });

    this.state = {
      cards: [],
      holders: []
    };
  }

  async componentDidMount() {
    this.getGraphCards()
  }

  get_sum = (balance) => {
    return _.parseInt(balance.unwrapped) + _.parseInt(balance.wrappedOfficial) + _.parseInt(balance.wrappedUnofficial)
  }

  async getGraphCards() {
    try {
      this.setState({
        loading: true,
        lookupFailure: false
      });
      
      let graphCards = (await getAllCards())

      let cards = _.cloneDeep(this.props.cards);

      cards.forEach(card => {
        // console.log(card.Name)
        let graphCard = _.filter(graphCards, function(value, key) { return value.name.toLowerCase() === card.Name.toLowerCase() })

        if(graphCard.length) {


          const balances = graphCard[0].balances.map(bal => {
            return {
              address: bal.id.split('-')[1],
              unwrapped: bal.unwrapped,
              wrappedUnofficial: bal.wrappedUnofficial,
              wrappedOfficial: bal.wrappedOfficial,
              sum: this.get_sum(bal)
            }
          })


          const hasWrappedCards = _.filter(balances, c => {
            return c.wrappedOfficial > 0
          }).length
      
          // console.log(hasWrappedCards)          

          card.Locked = graphCard[0].isLocked
          card.Supply = graphCard[0].supply
          card.Holders = balances
          card.HasWrappedOfficial = hasWrappedCards
        } else {
          console.log(`can't find ${card.Name}`)
        }
      })

      this.setState({
        cards: cards,
        loading: false,
      });
    } catch (e) {
      console.log(e)
    }
  }

  parseAmount(amount, decimals) {
    return parseFloat(amount / 10 ** this.props.card.Decimals).toFixed(decimals).toString()
  }

  render() {
    // if card prop was not passed, don't display anything
    if (!this.props.card)
      return null;

    const currentCard = this.state.cards.find(a => a.Name === this.props.card.Name) || null;
    const currentAddress = this.props.selectedAddress || null

console.log(this.props)

    const title = `Peperium – ${this.props.card.Name}`;
    const ogImage = "https://peperium.art" + cardImages[this.props.card.Number];
    const canonicalUrl = `https://peperium.art/card/${this.props.card.Number}`;
    const ogDescription = this.props.card.Description || 'no description..';

    return (
      <main className="overlay">
        <Helmet>
          <html lang="en" />
          <title>{title}</title>
          <link rel="canonical" href={canonicalUrl} />
          <meta name="description" content={ogDescription} />

          <meta property="og:title" content={title} />
          <meta property="og:image" content={ogImage} />
          <meta property="og:url" content={canonicalUrl} />
          <meta property="og:description" content={ogDescription} />

          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={ogDescription} />
          <meta name="twitter:image" content={ogImage} />
        </Helmet>

        <div className="icon close cell green" onClick={this.props.deselectCardCallback}> <img className="close-img" src={close} alt="Close card" /> </div>
        <article className="card-info">
          <div>
            <a href={`https://gateway.ipfs.io/ipfs/${this.props.card.IPFS_hash}`} target="_blank" rel="noreferrer">
              <img src={cardImages[this.props.card.Number]} alt={`${this.props.card.Name} card`} className="card-img-detail" />
            </a>
            {currentCard?.HasWrappedOfficial ? (<a href={`https://opensea.io/assets/ethereum/${wrapperAddr}/${this.props.card.Number}`} target="_blank" rel="noopener noreferrer" className="cell greenLight border shadow center button mt-3">
              {`View ${this.props.card.Name} on OpenSea`}
            </a>) : null}
            
            <a href={`https://looksrare.org/collections/${wrapperAddr}/${this.props.card.Number}`} target="_blank" rel="noopener noreferrer" className="cell greenLight border shadow center button mt-3">
              {`View ${this.props.card.Name} on LooksRare`}
            </a>
            <a href={`https://x2y2.io/eth/${wrapperAddr}/${this.props.card.Number}`} target="_blank" rel="noopener noreferrer" className="cell greenLight border shadow center button mt-3">
              {`View ${this.props.card.Name} on x2y2`}
            </a>
          </div>
          <div className="card-txt">
            <div className="pair-wrapper">
              <p className="cell green center border">{this.props.card.Name}</p>
              {/* <p className="cell greenLight center border">By {this.props.card.Artist}</p> */}
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Card info</p>
              <p className="cell white center border">Series {this.props.card.Series}, Card {this.props.card.Number}</p>
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Mint Date</p>
              <p className="cell white center border">{this.props.card.Creation_Date}</p>
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Total Supply</p>
              <p className="cell white center border">x{this.props.card.Supply}</p>
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Supply locked</p>
              <p className="cell white center border">{this.props.card.Locked ? '✅ Yes' : '❌ No ❌'}</p>
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Original contract</p>
              <p className="cell white center border"><a className="text-green" href={`https://etherscan.io/address/${this.props.card.Contract}`} target="_blank">{this.props.card.Contract}</a></p>
            </div>
            <div className="pair-cell">
              <p className="cell white center border">Original artwork</p>
              <p className="cell white center border"><a className="text-green" href={`https://ipfs.io/ipfs/${this.props.card.IPFS_hash}`} target="_blank">View on IPFS gateway</a></p>
            </div>

            <div className="description my-5 white">
              <h4>Description</h4>
              <div>{ogDescription}</div>
            </div>

            {currentCard?.Holders && <>
              <div className="holders white">
                <h4>Holders ({currentCard.Holders.length})</h4>
                <div className="table-responsive">
                  <table className="table table-striped text-green">
                    <thead>
                      <tr>
                        <th>Address</th>
                        <th className="text-right"><strong>Total cards</strong></th>
                        <th className="text-right">Unwrapped</th>
                        <th className="text-right">Old wrapper</th>
                        <th className="text-right">Wrapped</th>
                      </tr>
                    </thead>
                    <tbody>
                      {_.orderBy(currentCard.Holders, ['sum'], ['desc', 'desc']).map((h, id) => {
                        return (
                          <tr key={id} className={currentAddress === h.address ? `table-active` : ''}>
                            <td>
                              <a className="text-green" href={`/address/${h.address}`}>{h.address}</a>
                            </td>
                            <td className="text-right"><strong>{this.parseAmount(h.sum, this.props.card.Decimals)}</strong></td>
                            <td className="text-right">{this.parseAmount(h.unwrapped, this.props.card.Decimals)}</td>
                            <td className="text-right">{this.parseAmount(h.wrappedUnofficial, this.props.card.Decimals)}</td>
                            <td className="text-right">{this.parseAmount(h.wrappedOfficial, this.props.card.Decimals)}</td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </>
            }
          </div>
        </article>

      </main>
    );
  }
}

export default Detail
