import React from "react";
import "../global.css";
import "../reset.css";
import "./navbar.css"
import "../fonts/type.css"

class Navbar extends React.Component {
  render() {
    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
            <div className="container container-row px-4 px-lg-5">
                <a className="navbar-brand" href="/">Peperium</a>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation"><span className="navbar-toggler-icon"></span></button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0 ms-lg-4">
                        <li className="nav-item"><a className="nav-link">Cards</a></li>
                        <li className="nav-item"><a className="nav-link" href="#links">Links</a></li>
                        <li className="nav-item"><a className="nav-link" href="#technical">Technical</a></li>
                        <li className="nav-item"><a className="nav-link" href="#undeadpepe">UNDEADPEPE</a></li>
                        <li className="nav-item"><a className="nav-link" target="_blank" href="https://crypt0biwan.medium.com/the-lost-peperium-pepes-346a5e6a5bcf">Medium</a></li>
                        <li className="nav-item"><a className="nav-link" target="_blank" href="https://opensea.io/collection/peperium-2017">OpenSea</a></li>
                        <li className="nav-item"><a className="nav-link" target="_blank" href="https://looksrare.org/collections/0xFe880206214856F984D4f64Fc89c26681DcA15a2">LooksRare</a></li>
                        <li className="nav-item"><a className="nav-link" target="_blank" href="https://magiceden.io/collections/ethereum/0xfe880206214856f984d4f64fc89c26681dca15a2">MagicEden</a></li>
                    </ul>

                    <a target="_blank" className="btn btn-discord" href="https://discord.gg/UdJET9ZNvf">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-discord" viewBox="0 0 16 16">
                            <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"></path>
                        </svg>
                        <span className="ml-3">Discord</span>
                    </a>
                </div>
            </div>
        </nav>
    )
  }
}

export default Navbar
