import React from "react";
import { navigate } from "gatsby"
import { ethers } from "ethers"

import "../global.css";
import "../reset.css";
import "./gallery.css"
import "../fonts/type.css"

import close from "../../images/i-close.svg"
import search from "../../images/i-search.svg"
import { InfuraProviderId, cardImages } from "../constants.jsx";
import { getCards, getAllCards, getCardIDFromAddress } from "../../services/graph";

import Resolution from "@unstoppabledomains/resolution";
const _ = require('lodash');

class Gallery extends React.Component {
  constructor(props) {
    super(props);

    const provider = new ethers.providers.InfuraProvider("homestead", {
      projectId: InfuraProviderId,
    });

    this.state = {
      cards: [],
      selectedAddress: props.selectedAddress,
      provider: provider,
      loading: true,
      lookupFailure: false
    };

    this.handleAddressSubmit = this.handleAddressSubmit.bind(this);
    this.handleCardClick = this.handleCardClick.bind(this);
  }

  async componentDidMount() {
    // asynchronously kick off address lookup
    if(this.state.selectedAddress) {
      this.updateCards(this.state.selectedAddress)
    } else {
      this.getGraphCards()
    }
  }

  handleAddressSubmit(e) {
    e.preventDefault(); // prevent default form action

    if (this.state.selectedAddress) {
      // address already set- clear it
      navigate("/");
      this.updateCards();
    } else {
      const addr = document.getElementById("address-input").value;
      if (addr) {
        navigate(`/?address=${addr}`);
        this.updateCards(addr);
      } else {
        navigate("/");
        this.updateCards();
      }
    }
  }

  handleCardClick(e) {
    const id = e.target.closest("figure").id;
    if (Number(id) === Number(this.props.selectedCardNumber)) {
      // already selected, deselect it
      this.props.deselectCardCallback();
    } else {
      this.props.selectCardCallback(e.target.closest("figure").id);
    }
  }

  async updateCards(inputAddress) {
    this.setState({
      loading: true,
      lookupFailure: false
    });

    let cards = _.cloneDeep(this.props.cards);
    let Holdings = {};
    // let Locked = {}

    if (!inputAddress){
      this.setState({
        cards: cards,
        loading: false,
      });
      return;
    }

    var parsedInput = inputAddress;
    try {
      // parse ENS/UD domain names
      console.debug(`examining addr ${inputAddress}...`);
      if (inputAddress.includes(".")) {
        // ENS name?
        // console.debug("parse as ens...");
        parsedInput = await this.state.provider.resolveName(inputAddress);
        // console.debug(`parsedInput: ${parsedInput}`);

        if (!parsedInput) {
          // console.debug("parse as UD");
          // detect if unstoppable domain
          const resolution = Resolution.fromEthersProvider(this.state.provider);
          parsedInput = await resolution.addr(inputAddress, "ETH");
          // console.debug(`parsedInput: ${parsedInput}`);
        }
      }

      // Ensure computed address is valid
      // var result = ethers.utils.getAddress(parsedInput)
    }
    catch (e) {
      console.warn("Unable to parse address");
      console.error(e);
      this.setState({
        cards: [],
        loading: false,
        lookupFailure: true
      });
      return;
    }
    
    // console.log(`parsedInput: ${parsedInput}`)
    
    if (parsedInput) {
      // Fetch Holdings for address
      try {
        var cardBalances = await getCards(parsedInput.toLowerCase());
      } catch (e) {
        console.warn("Unable to fetch balances");
        console.error(e);
        this.setState({
          lookupFailure: true
        });
        return;
      }

      if(cardBalances.length === 0) {
        this.setState({
          cards: [],
          loading: false,
        });
        return;
      }

      for (let i = 0; i < cardBalances.length; i++) {
        let cardId = getCardIDFromAddress(cardBalances[i].type.id)
        Holdings[cardId] = {
          "erc1155": parseInt(cardBalances[i].wrappedOfficial) + parseInt(cardBalances[i].wrappedUnofficial),
          "erc20": parseInt(cardBalances[i].unwrapped)
        };
        // Locked[cardId] = cardBalances[i].isLocked
      }

      // Populate address supply
      if (Object.keys(Holdings).length > 0) {
        for (let i = 0; i < cards.length; i++) {
          if (cards[i].Number in Holdings) {
            cards[i].Supply = Holdings[cards[i].Number].erc1155 + Holdings[cards[i].Number].erc20;
            cards[i].Holdings = Holdings[cards[i].Number];
          } else {
            cards[i].Supply = 0;
          }
        }
      }

      // console.log(`Peperium balance for address ${parsedInput}:\n${JSON.stringify(Holdings, null, 2)}`);
    }

    this.setState({
      cards: cards,
      loading: false,
    });
  }

  async getGraphCards() {
    try {
      this.setState({
        loading: true,
        lookupFailure: false
      });
      
      let graphCards = (await getAllCards())

      let cards = _.cloneDeep(this.props.cards);

      cards.forEach(card => {
        // console.log(card.Name)
        let graphCard = _.filter(graphCards, function(value, key) { return value.name.toLowerCase() === card.Name.toLowerCase() })

        if(graphCard.length) {
          card.Locked = graphCard[0].isLocked
          card.Supply = graphCard[0].supply
          card.Holders = graphCard[0].balances.length
        } else {
          console.log(`can't find ${card.Name}`)
        }
      })

      this.setState({
        cards: cards,
        loading: false,
      });
    } catch (e) {
      console.log(e)
    }
  }

  renderCards(cards) {
    return cards.map(card => {
      if (card.Holdings != null) {
        // handle as user balance
        if (card.Holdings.erc20 > 0 || card.Holdings.erc1155 > 0) {
          return <figure className={`card ${card.Number === Number(this.props.selectedCardNumber) ? "scale-down" : ""}`} onClick={this.handleCardClick} key={card.Number} id={card.Number}>
              <div className={`card-eth-overlay ${card.Number === Number(this.props.selectedCardNumber) ? "selected" : ""}`}></div>
              <div className='card-img-holder'>
                <img src={cardImages[card.Number]} alt="" className={`card-img ${card.Number === this.props.selectedCardNumber ? "grayscale" : ""}`} />
              </div>
              <figcaption className="card-title cell green center border">{card.Name}</figcaption>
              {/* <figcaption className="card-title cell green center border">{card.title}</figcaption> */}
              {/* <p className="card-artist cell white center border">By {card.artist}</p> */}
              <div className="pair-cell">
                <p className="card-price cell greenLight center border" title="Wrapped">Wrapped</p>
                <p className="card-supply cell green center border" title="Wrapped card supply">x{card.Holdings.erc1155 / 10 ** card.Decimals}</p>
              </div>
              <div className="pair-cell">
                <p className="card-price cell greenLight center border" title="Unwrapped">Unwrapped</p>
                <p className="card-supply cell greenLight center border" title="Unwrapped card supply">x{card.Holdings.erc20}</p>
              </div>
            </figure>;
        } else {
          return null;
        }
      } else if (card.Supply > 0) {
        // handle as general card display
        return <figure className={`card ${card.Number === Number(this.props.selectedCardNumber) ? "scale-down" : ""}`} onClick={this.handleCardClick} key={card.Number} id={card.Number}>
            <div className={`card-overlay ${card.Number === Number(this.props.selectedCardNumber) ? "selected" : ""}`}></div>
            <div className='card-img-holder'>
              <img src={cardImages[card.Number]} alt="" className={`card-img ${card.Number === this.props.selectedCardNumber ? "grayscale" : ""}`} />
            </div>
            <figcaption className="card-title cell green center border">{card.Name}</figcaption>
            {/* <p className="card-artist cell white center border">By {card.artist}</p> */}
            <div className="pair-cell">
              <p className="card-price cell greenLight center border">Locked</p>
              <p className="card-supply cell green center border">{card.Locked ? "Yes" : "No"}</p>
            </div>
            <div className="pair-cell">
              <p className="card-price cell greenLight center border">Supply</p>
              <p className="card-supply cell green center border">{card.Supply / 10 ** card.Decimals}</p>
            </div>
            <div className="pair-cell">
              <p className="card-price cell greenLight center border">Holders</p>
              <p className="card-supply cell green center border">{card.Holders}</p>
            </div>
          </figure>;
      } else {
        // not ready yet
        return null;
      }
    });
  }

  render() {
    let cards = _.cloneDeep(this.state.cards);

    // sort
    switch (this.props.sort) {
      case "Chronological":
        cards.sort((a, b) => a.Number - b.Number);
        break;
      case "Supply: Low to High":
        cards.sort((a, b) => a.Supply - b.Supply);
        break;
      case "Supply: High to Low":
        cards.sort((a, b) => b.Supply - a.Supply);
        break;
      default:
        console.warn("Unexpected sort case fallthrough");
    }

    let gallery = null;
    if (this.state.lookupFailure) {
      gallery = <p className="warning-message">{`Error fetching cards for address ${this.state.selectedAddress}.`}</p>;
    } else if (this.state.loading) {
      gallery = <p className="warning-message">Loading...</p>;
    } else if (cards.find(card => card.Name !== "")) {
      gallery = <>
        <h3 className="gallery-header">Series 1</h3>
        <section className="gallery">
          {this.renderCards(_.filter(cards, function(card) { return card.Series === "1" }))}
        </section>
        <h3 className="gallery-header">Series 2</h3>
        <section className="gallery">
          {this.renderCards(_.filter(cards, function(card) { return card.Series === "2" }))}
        </section>
        <h3 className="gallery-header">Fake Peperium</h3>
        <section className="gallery">
          {this.renderCards(_.filter(cards, function(card) { return card.Series === "Fake Peperium" }))}
        </section>
        </>
    } else {
      gallery = <p className="warning-message">{`No cards for address ${this.state.selectedAddress}.`}</p>;
    }

    let formattedAddress = this.state.selectedAddress;
    if (formattedAddress && formattedAddress.match(/^0x[a-fA-F0-9]{40}$/)) {
      formattedAddress = formattedAddress.substring(0, 10) + "..." + formattedAddress.slice(-10);
    }

    return (
      <aside>
        {/* Sorter */}
        <nav className="nav-form">
          <div className="nav-inner">
            <form onSubmit={this.handleAddressSubmit} autoComplete="off">
              <input type="text" className="cell border white input__eth" id="address-input" autoComplete="off" spellCheck="false"
                placeholder="Address or Domain Lookup" defaultValue={formattedAddress} disabled={!!this.state.selectedAddress} />
              {
                !!this.state.selectedAddress ?
                  <button className="icon dropdown__icon cell green"> <img src={close} alt="Close address" /> </button>
                :
                  <button className="icon dropdown__icon cell green"> <img src={search} alt="Search for address" /> </button>
              }
            </form>
          </div>
        </nav>

        {/* Gallery */}
        {gallery}

      </aside>
    );
  }
}

export default Gallery